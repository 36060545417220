var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.formattedOperationList.length && _vm.inProccessing)?_c('v-card',[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"text-field, table, data-table"}})],1)],1):_c('v-card',[_c('v-card-title',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" Liste des opérations traitées ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Rechercher","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink text-center",attrs:{"headers":_vm.headers,"items":_vm.formattedOperationList,"options":_vm.dataOptions,"search":_vm.search,"items-per-page":15,"sort-by":"id","locale":"fr"},scopedSlots:_vm._u([{key:"header.subsidised_amount",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 mr-1",attrs:{"outlined":"","primary":"","x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('h2',[_vm._v("?")])])]}}],null,true)},[_c('span',[_vm._v("Montant auquel le taux de réduction a été appliqué")])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text-body-1 text-truncate text--primary"},[_vm._v(_vm._s(item.created_at))])])])]}},{key:"item.request_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate primary--text"},[_vm._v(" "+_vm._s(item.numberOfDetail)+" "+_vm._s(item.request_type)+_vm._s(parseInt(item.numberOfDetail)>1?"S":"")+" ")])])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('span',{staticClass:"d-block font-weight-bold text-truncate primary--text"},[_vm._v(" "+_vm._s(item.amount)+" ")])])])]}},{key:"item.subsidised_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('span',{staticClass:"d-block font-weight-bold text-truncate success--text"},[_vm._v(" "+_vm._s(item.subsidised_amount)+" ")])])])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column justify-center"},[(item.state===1)?_c('v-chip',{staticClass:"ma-2 text-center pr-5 pl-5",attrs:{"color":"#F1FFE8FF"}},[_c('b',{staticClass:"success--text"},[_vm._v("Traitée")])]):(item.state===0)?_c('v-chip',{staticClass:"ma-2 text-center",attrs:{"color":"#FFF6ECFF"}},[_c('b',{staticClass:"custom-warning--text"},[_vm._v("En cours")])]):_c('v-chip',{staticClass:"ma-2 text-center",attrs:{"color":"#FFECECFF"}},[_c('b',{staticClass:"error--text"},[_vm._v("Rejeté")])])],1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-12 w-"},[_c('v-btn',{staticClass:"ml-1",attrs:{"outlined":"","x-small":"","color":"info"},on:{"click":function($event){return _vm.details(item)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]),_vm._v(" Détails ")],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.titleDetailsDialog)+" "),_c('b')])]),_c('v-divider'),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headersDetails,"items":_vm.detailsFormatted,"options":_vm.detailsOptions,"items-per-page":-1,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.num_ticket",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[(item.num_ticket)?_c('span',{staticClass:"d-block font-weight-semibold text-uppercase text-truncate primary--text font-weight-bold"},[_vm._v("N° "+_vm._s(item.num_ticket))]):_c('span',{staticClass:"d-block font-weight-semibold text-uppercase text-truncate primary--text font-weight-bold"},[_vm._v("N/A")]),_c('v-spacer'),_c('v-col',{staticClass:"align-content-end",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-y":"","right":"","close-on-content-click":false,"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 custom-btn",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiHistory)+" ")]),_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiMenuDown)+" ")])],1)]}}],null,true)},[_c('v-simple-table',{staticClass:"border-light",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"colspan":"2"}},[_vm._v(" Transactions ")])])]),_c('tbody',_vm._l((item.service_requests_detail_transactions),function(transaction,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-truncate bordure"},[_vm._v(" "+_vm._s(_vm.formatDate(transaction.created_at))+" ")]),_c('td',{staticClass:"bordure"},[_vm._v(" "+_vm._s(transaction.message)+" ")])])}),0)]},proxy:true}],null,true)})],1)],1)],1)])]}},{key:"item.decodeur",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-bold text-truncate primary--text"},[_vm._v(_vm._s(item.decodeurInfo.location)+" - "+_vm._s(item.decodeurInfo.number))])])])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_vm._v(" "+_vm._s(item.comment !== null ? item.comment : "N/A")+" ")])])]}},{key:"item.offre",fn:function(ref){
var item = ref.item;
return [(item.operation === 1)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('h4',{staticClass:"d-block font-weight-semibold text-truncate primary--text"},[_vm._v(" Offre sollicité : "),_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(item.formulaInfo.label))])]),_c('v-spacer'),_c('h4',{staticClass:"d-block font-weight-semibold text-truncate primary--text"},[_vm._v(" Option : "),_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v(_vm._s(item.optionInfo.nom))])]),_c('v-spacer'),_c('h4',{staticClass:"d-block font-weight-semibold text-truncate primary--text mb-2"},[_vm._v(" Durée : "),_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v(_vm._s(item.duration))]),_vm._v(" mois ")])],1)]):_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3 p-2"},[_c('h4',{staticClass:"d-block font-weight-semibold text-truncate primary--text mt-2"},[_vm._v(" Offre actuel: "),_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v(_vm._s(item.oldFormulaInfo.label))])]),_c('h4',{staticClass:"d-block font-weight-semibold text-truncate primary--text mb-2"},[_vm._v(" Option : "),_c('span',{staticClass:"font-weight-bold primary--text"},[_vm._v(_vm._s(item.oldOptionInfo.nom))])]),_c('v-divider'),_c('h4',{staticClass:"d-block font-weight-semibold text-truncate primary--text mt-2"},[_vm._v(" Offre sollicité: "),_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(item.newFormulaInfo.label))])]),_c('h4',{staticClass:"d-block font-weight-semibold text-truncate primary--text mb-2"},[_vm._v(" Option : "),_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(item.newOptionInfo.nom))])])],1)])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('p',{staticClass:"d-block font-weight-bold text-truncate primary--text"},[_c('span',{staticClass:"d-block"},[_vm._v("Fin d'abonnement Actuelle")]),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.moment(item.decodeurInfo.end_subscription).format('DD/MM/YYYY')))])]),_c('p',{staticClass:"d-block font-weight-bold text-truncate primary--text"},[_c('span',{staticClass:"d-block"},[_vm._v("Activation")]),_vm._v(" "+_vm._s(item.activation_date)+" ")]),_c('p',{staticClass:"d-block font-weight-bold text-truncate primary--text"},[_c('span',{staticClass:"d-block"},[_vm._v("Fin d'abonnement Prévue")]),_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.moment(item.decodeurInfo.end_subscription).subtract(1,"day").add(item.duration,"month").format('DD/MM/YYYY')))])])])])]}},{key:"item.montant",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-bold text-truncate primary--text"},[_vm._v(" "+_vm._s(_vm.withCurrency(item.amount))+" ")])])])]}},{key:"item.subsidised_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-bold text-truncate success--text"},[_vm._v(" "+_vm._s(_vm.withCurrency(item.subsidised_amount))+" ")])])])]}},{key:"item.subsidised_amount_paid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-bold text-truncate success--text"},[_vm._v(" "+_vm._s(_vm.withCurrency(item.subsidised_amount_paid))+" ")])])])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[(item.state===1)?_c('v-chip',{staticClass:"ma-2 text-center pr-5 pl-5",attrs:{"color":"#F1FFE8FF"}},[_c('b',{staticClass:"success--text"},[_vm._v("Traité")])]):(item.state===0)?_c('v-chip',{staticClass:"ma-2 text-center",attrs:{"color":"#FFF6ECFF"}},[_c('b',{staticClass:"custom-warning--text"},[_vm._v("En cours")])]):_c('v-chip',{staticClass:"ma-2 text-center",attrs:{"color":"#FFECECFF"}},[_c('b',{staticClass:"error--text"},[_vm._v("Rejeté")])])],1)])]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.close}},[_vm._v(" Ok ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }