<template>
  <div>
    <v-card v-if="!formattedOperationList.length && inProccessing">
      <v-sheet
        :color="`grey`"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="text-field, table, data-table"
        >
        </v-skeleton-loader>
      </v-sheet>
    </v-card>
    <v-card v-else>
      <v-card-title class="text-uppercase font-weight-bold">
        Liste des opérations traitées
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            offset-md="8"
            md="4"
          >
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Rechercher"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="formattedOperationList"
        :options="dataOptions"
        :search="search"
        :items-per-page="15"
        sort-by="id"
        locale="fr"
        class="table-kitchen-sink text-center"
      >

        <template #header.subsidised_amount="{header}">
          {{ header.text }}
          <v-tooltip
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1 mr-1"
                v-bind="attrs"
                outlined
                primary
                x-small
                icon
                v-on="on"
              >
                <h2>?</h2>
              </v-btn>
            </template>
            <span>Montant auquel le taux de réduction a été appliqué</span>
          </v-tooltip>
        </template>
        <!--          Date de programmation -->
        <template #[`item.created_at`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold text-body-1 text-truncate text--primary">{{ item.created_at }}</span>
            </div>
          </div>
        </template>

        <!-- nombre decodeur -->
        <template #[`item.request_type`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column justify-center">
              <span class="d-block font-weight-semibold text-truncate primary--text"> {{ item.numberOfDetail }} {{ item.request_type }}{{ parseInt(item.numberOfDetail)>1?"S":"" }} </span>
            </div>
          </div>
        </template>

        <!-- Montant -->
        <template #[`item.amount`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column justify-center">
              <span class="d-block font-weight-bold text-truncate primary--text"> {{ item.amount }} </span>
            </div>
          </div>
        </template>

        <template #[`item.subsidised_amount`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column justify-center">
              <span class="d-block font-weight-bold text-truncate success--text"> {{ item.subsidised_amount }} </span>
            </div>
          </div>
        </template>

        <template #[`item.state`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column justify-center">
              <v-chip
                v-if="item.state===1"
                class="ma-2 text-center pr-5 pl-5"
                color="#F1FFE8FF"
              >
                <b class="success--text">Traitée</b>
              </v-chip>
              <v-chip
                v-else-if="item.state===0"
                class="ma-2 text-center"
                color="#FFF6ECFF"
              >
                <b class="custom-warning--text">En cours</b>
              </v-chip>
              <v-chip
                v-else
                class="ma-2 text-center"
                color="#FFECECFF"
              >
                <b class="error--text">Rejeté</b>
              </v-chip>
            </div>
          </div>
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="col-12 w-">
            <v-btn
              v-model="dialog"
              class="ml-1"
              outlined
              x-small
              color="info"
              @click="details(item)"
            >
              <v-icon
                small
                class="me-1"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
              Détails
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialog"
        persistent
      >
        <v-card>
          <v-card-title>
            <span> {{ titleDetailsDialog }} <b></b></span>
          </v-card-title>
          <v-divider></v-divider>
          <v-data-table
            :headers="headersDetails"
            :items="detailsFormatted"
            :options="detailsOptions"
            :items-per-page="-1"
            sort-by="id"
            class="table-kitchen-sink"
          >
            <template #[`item.num_ticket`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  <span
                    v-if="item.num_ticket"
                    class="d-block font-weight-semibold text-uppercase text-truncate primary--text font-weight-bold"
                  >N° {{ item.num_ticket }}</span>
                  <span
                    v-else
                    class="d-block font-weight-semibold text-uppercase text-truncate primary--text font-weight-bold"
                  >N/A</span>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                    class="align-content-end"
                  >
                    <v-menu
                      offset-y
                      right
                      :close-on-content-click="false"
                      transition="slide-y-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          class="ma-2 custom-btn"
                          text
                          v-on="on"
                        >
                          <v-icon
                            class="error--text"
                          >
                            {{ icons.mdiHistory }}
                          </v-icon>
                          <v-icon>
                            {{ icons.mdiMenuDown }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-simple-table class="border-light">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                class="text-left"
                                colspan="2"
                              >
                                Transactions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(transaction, index) in item.service_requests_detail_transactions"
                              :key="index"
                            >
                              <td class="text-truncate bordure">
                                {{ formatDate(transaction.created_at) }}
                              </td>
                              <td class="bordure">
                                {{ transaction.message }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-menu>
                  </v-col>
                </div>
              </div>
            </template>

            <!-- nombre decodeur -->
            <template #[`item.decodeur`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  <span class="d-block font-weight-bold text-truncate primary--text">{{ item.decodeurInfo.location }} - {{ item.decodeurInfo.number }}</span>
                </div>
              </div>
            </template>

            <template #[`item.comment`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column justify-center">
                  {{ item.comment !== null ? item.comment : "N/A" }}
                </div>
              </div>
            </template>

            <template #[`item.offre`]="{item}">
              <div
                v-if="item.operation === 1"
                class="d-flex align-center"
              >
                <div class="d-flex flex-column ms-3">
                  <h4 class="d-block font-weight-semibold text-truncate primary--text">
                    Offre sollicité : <span class="success--text">{{ item.formulaInfo.label }}</span>
                  </h4>
                  <v-spacer></v-spacer>
                  <h4 class="d-block font-weight-semibold text-truncate primary--text">
                    Option : <span class="font-weight-bold primary--text">{{ item.optionInfo.nom }}</span>
                  </h4>
                  <v-spacer></v-spacer>
                  <h4 class="d-block font-weight-semibold text-truncate primary--text mb-2">
                    Durée : <span class="font-weight-bold primary--text">{{ item.duration }}</span> mois
                  </h4>
                </div>
              </div>
              <div
                v-else
                class="d-flex align-center"
              >
                <div class="d-flex flex-column ms-3 p-2">
                  <h4 class="d-block font-weight-semibold text-truncate primary--text mt-2">
                    Offre actuel: <span class="font-weight-bold primary--text">{{ item.oldFormulaInfo.label }}</span>
                  </h4>
                  <h4 class="d-block font-weight-semibold text-truncate primary--text mb-2">
                    Option : <span class="font-weight-bold primary--text">{{ item.oldOptionInfo.nom }}</span>
                  </h4>
                  <v-divider></v-divider>

                  <h4 class="d-block font-weight-semibold text-truncate primary--text mt-2">
                    Offre sollicité: <span class="success--text">{{ item.newFormulaInfo.label }}</span>
                  </h4>
                  <h4 class="d-block font-weight-semibold text-truncate primary--text mb-2">
                    Option : <span class="success--text">{{ item.newOptionInfo.nom }}</span>
                  </h4>
                </div>
              </div>
            </template>

            <template #[`item.date`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  <p class="d-block font-weight-bold text-truncate primary--text">
                    <span class='d-block'>Fin d'abonnement Actuelle</span>
                    <span class='error--text'>{{ moment(item.decodeurInfo.end_subscription).format('DD/MM/YYYY') }}</span>
                  </p>
                  <p class="d-block font-weight-bold text-truncate primary--text">
                    <span class='d-block'>Activation</span>
                    {{ item.activation_date }}
                  </p>
                  <p class="d-block font-weight-bold text-truncate primary--text">
                    <span class='d-block'>Fin d'abonnement Prévue</span>
                    <span class='success--text'>{{ moment(item.decodeurInfo.end_subscription).subtract(1,"day").add(item.duration,"month").format('DD/MM/YYYY') }}</span>
                  </p>
                </div>
              </div>
            </template>

            <template #[`item.montant`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  <span class="d-block font-weight-bold text-truncate primary--text">
                    {{ withCurrency(item.amount) }}
                  </span>
                </div>
              </div>
            </template>

            <template #[`item.subsidised_amount`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  <span class="d-block font-weight-bold text-truncate success--text">
                    {{ withCurrency(item.subsidised_amount) }}
                  </span>
                </div>
              </div>
            </template>

            <template #[`item.subsidised_amount_paid`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  <span class="d-block font-weight-bold text-truncate success--text">
                    {{ withCurrency(item.subsidised_amount_paid) }}
                  </span>
                </div>
              </div>
            </template>

            <template #[`item.state`]="{item}">
              <div class="d-flex align-center">
                <div class="d-flex flex-column ms-3">
                  <v-chip
                    v-if="item.state===1"
                    class="ma-2 text-center pr-5 pl-5"
                    color="#F1FFE8FF"
                  >
                    <b class="success--text">Traité</b>
                  </v-chip>
                  <v-chip
                    v-else-if="item.state===0"
                    class="ma-2 text-center"
                    color="#FFF6ECFF"
                  >
                    <b class="custom-warning--text">En cours</b>
                  </v-chip>
                  <v-chip
                    v-else
                    class="ma-2 text-center"
                    color="#FFECECFF"
                  >
                    <b class="error--text">Rejeté</b>
                  </v-chip>
                </div>
              </div>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="close"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import {
  computed, onMounted, ref, onBeforeMount,
} from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
  mdiHistory,
  mdiMenuDown,
  mdiAlphaICircleOutline,
} from '@mdi/js'
import data from '@/views/locaux/datatable'
import Http from '@/helpers/http'

import Vue from 'vue'
import moment from 'moment'
import useDynamicConstant from '@/helpers/useDynamicConstant'

Vue.prototype.moment = moment

export default {
  setup() {
    const search = ref('')
    const operationList = ref([])
    const operation = ref([{}])
    const formule = ref([{}])
    const option = ref([{
      alpha2: 'CM',
      code: null,
      id: 0,
      nom: 'Aucune',
      pu: 0,
    }])
    const decodeurs = ref([{}])
    const dialog = ref(false)
    const detailItem = ref([])

    const inProccessing = ref(true)
    const loadFormules = () => {
      Http.get('get-formules')
        .then(response => {
          formule.value = response.data
          console.log('chargement des formules terminées')
          console.log(formule.value)
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const loadOptions = () => {
      Http.get('get-options')
        .then(response => {
          option.value = [...option.value, ...response.data]

          console.log('chargement des options terminés')
          console.log(option.value)
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const contrat = localStorage.getItem('defaultContrat') !== undefined ? JSON.parse(localStorage.getItem('defaultContrat')): []

    const loadDecodeurs = () => {
      // const user = JSON.parse(Session.get('userData'))
      // const role = JSON.parse(localStorage.getItem('defaultRole'))
      // console.log(contrat)

      // const parameters = { id: user.id, roleId: role.id, contractId: contrat.id }
      // console.log(parameters)

      Http.get(`get-decoders/${contrat.id}`)
        // eslint-disable-next-line no-return-assign
        .then(response => {
          decodeurs.value = response.data

          console.log('chargement des decodeurs terminés')
          console.log(decodeurs.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const formatDate = date => moment(date).format('DD/MM/YYYY H:mm:ss')

    onBeforeMount(() => {
      loadDecodeurs()
      // eslint-disable-next-line no-use-before-define
      loadOperations()
    })

    const { CURRENCY } = useDynamicConstant()

    const currency = ref(CURRENCY)

    onMounted(() => {
      // eslint-disable-next-line no-use-before-define

      // eslint-disable-next-line no-use-before-define
      loadFormules()
      loadOptions()
    })

    const loadOperations = () => {
      Http.get('request-type')
        .then(response => {
          operation.value = response.data
          console.log('chargement des operations terminés')
          console.log(operation.value)
          // eslint-disable-next-line no-use-before-define
          loadRequest()
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const loadRequest = () => {
      Http.get(
        `get-service-request-treated/${JSON.parse(localStorage.getItem('defaultContrat')).id}`,
      ).then(rep => {
        console.log(rep.data)
        operationList.value = rep.data
        inProccessing.value = false
      }).catch(error => {
        let errors
        console.error('Oops, Erreur rencontrée !')
        if (error.status === 422) {
          console.log('error 422 :>> ', error.data.errors)
          errors.value = error.data.errors
        } else if (error.status === 401) {
          console.log('error 401 :>> ', error.data)
          errors.value = { dataError: error.data.message }
        } else if (error.status === 400) {
          console.log('error 400 :>> ', error.data)
          errors.value = { dataError: error.data.message }
        } else {
          console.log('error :>> ', error)
          errors.value = { dataError: 'Votre connexion internet est instable' }
        }
      })
    }

    const detailItems = computed(() => detailItem.value)

    const groupOperationsByDate = () => {
      const tabOperationDate = []
      let i = 0
      // eslint-disable-next-line no-plusplus

      tabOperationDate.push({
        // eslint-disable-next-line radix
        ticket: parseInt(moment(operationList.value[0].created_at).format('YYYYMMDDHHmm')),
        date: operationList.value[0].created_at,
        operation: operationList.value[0].number_of_detail,
        // eslint-disable-next-line radix
        montant: parseInt(operationList.value[0].amount),
        details: [operationList.value[0]],
      })

      // eslint-disable-next-line no-plusplus
      for (i = 1; i < operationList.value.length; i++) {
        const l = tabOperationDate.length - 1
        console.log('difference days')
        const dayP = moment(tabOperationDate[l].date).format('YYYY-MM-DD')
        console.log(dayP)
        const dayN = moment(operationList.value[i].created_at).format('YYYY-MM-DD')
        console.log(dayN)
        console.log(moment(dayP).diff(dayN, 'days'))
        if ((moment(dayP).diff(dayN, 'days')) === 0) {
          tabOperationDate[l].operation += operationList.value[i].number_of_detail
          // eslint-disable-next-line radix
          tabOperationDate[l].montant += parseInt(operationList.value[i].amount)
          tabOperationDate[l].details = [...tabOperationDate[l].details, ...[operationList.value[i]]]
        } else {
          tabOperationDate.push({
            // eslint-disable-next-line radix
            ticket: parseInt(moment(operationList.value[i].created_at).format('YYYYMMDDHHmm')),
            date: operationList.value[i].created_at,
            operation: operationList.value[i].number_of_detail,
            montant: operationList.value[i].amount,
            details: [operationList.value[i]],
          })
        }
      }

      console.log('tabOperationDate')
      console.log(tabOperationDate)

      tabOperationDate.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.date = moment(item.date).format('DD/MM/YYYY')
      })

      return tabOperationDate
    }

    const groupOperationDate = computed(() => groupOperationsByDate())

    const formatItemDetails = item => {
      // eslint-disable-next-line no-shadow
      console.log('item')
      console.log(item)
      const result = {
        totalAmount: '',
        isMany: false,
        details: [],
        operation: item.request_type_id,
      }

      // eslint-disable-next-line no-plusplus
      if (item.request_type_id === 1) {
        const reabo = item
        reabo.service_request_details.forEach(opDetail => {
          // eslint-disable-next-line no-param-reassign,array-callback-return
          opDetail.operation = 1
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          opDetail.decodeurInfo = decodeurs.value.filter(d => d.id === opDetail.decoder_id)[0]

          console.log('getdecodeurInfo')
          console.log(decodeurs.value.filter(d => d.id === opDetail.decoder_id)[0])
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          opDetail.activation_date = moment(opDetail.activation_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          opDetail.formulaInfo = formule.value.filter(f => f.code === opDetail.formula1)[0]
          // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
          opDetail.optionInfo = option.value.filter(o => o.code === opDetail.option1)[0]
        })

        result.totalAmount = reabo.amount
        result.isMany = (reabo.number_of_detail > 1)
        result.details = reabo.service_request_details

        return result
      }

      const upgrade = item

      upgrade.service_request_details.forEach(opDetail => {
        // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
        opDetail.decodeurInfo = decodeurs.value.filter(d => d.id === opDetail.decoder_id)[0]
        // eslint-disable-next-line no-param-reassign,array-callback-return
        opDetail.operation = 2
        // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
        opDetail.activation_date = moment(opDetail.activation_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
        opDetail.oldFormulaInfo = formule.value.filter(f => f.code === opDetail.formula1)[0]
        // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
        opDetail.newFormulaInfo = formule.value.filter(f => f.code === opDetail.formula2)[0]
        // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
        opDetail.oldOptionInfo = option.value.filter(o => o.code === opDetail.option1)[0]
        // eslint-disable-next-line no-param-reassign,array-callback-return,prefer-destructuring
        opDetail.newOptionInfo = option.value.filter(o => o.code === opDetail.option2)[0]
      })

      result.totalAmount = upgrade.amount
      result.isMany = (upgrade.number_of_detail > 1)
      result.details = upgrade.service_request_details

      return result
    }

    const detailsFormatted = computed(() => detailItem.value)
    const plurals = ref(false)
    const typeOperation = ref(0)

    const details = item => {
      const detailsOperation = formatItemDetails(item)
      detailItem.value = detailsOperation.details
      plurals.value = detailsOperation.isMany
      typeOperation.value = detailsOperation.operation
      console.log('formatItemDetails')
      console.log(detailItem.value)
      dialog.value = true
    }

    const withCurrency = price => `${Number(price).toLocaleString()} ${CURRENCY}`

    const formattedOperationList = computed(() => {
      operationList.value.forEach(item => {
        // eslint-disable-next-line no-use-before-define,no-param-reassign
        item.start_date = moment(item.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        // eslint-disable-next-line no-use-before-define,no-param-reassign
        item.end_date = moment(item.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        // eslint-disable-next-line no-use-before-define,no-param-reassign
        item.created_at = moment(item.created_at).format('DD/MM/YYYY à HH:mm:ss')
        // eslint-disable-next-line no-use-before-define,no-param-reassign
        item.numberOfDetail = item.number_of_detail
        // eslint-disable-next-line no-param-reassign,array-callback-return,consistent-return
        const type = operation.value.filter(i => i.id === item.request_type_id)
        // eslint-disable-next-line no-param-reassign,array-callback-return,consistent-return
        item.request_type = type[0].name
        // eslint-disable-next-line no-use-before-define,no-param-reassign
        // item.request_type = item.request_type[0].toUpperCase() + item.request_type.slice(1)
        // eslint-disable-next-line no-use-before-define,no-param-reassign
        item.amount = withCurrency(item.amount)
        // eslint-disable-next-line no-use-before-define,no-param-reassign
        item.subsidised_amount = withCurrency(item.subsidised_amount)
      })

      return operationList.value
    })

    const titleDetailsDialog = computed(() => {
      console.log('typeOperation')
      console.log(typeOperation.value)
      console.log('plurals')
      console.log(plurals.value)
      if (typeOperation.value === 1) {
        if (plurals.value) {
          return 'Détails des réabonnements'
        }

        return 'Détails du réabonnement'
      }

      if (plurals.value) {
        return 'Détails des Upgrades'
      }

      return 'Détails de l\'Upgrade'
    })

    return {
      loadOperations,
      formattedOperationList,
      currency,
      withCurrency,
      details,
      detailItems,
      groupOperationsByDate,
      groupOperationDate,
      formatItemDetails,
      loadRequest,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiEyeOutline,
        mdiHistory,
        mdiMenuDown,
        mdiAlphaICircleOutline,
      },
      formule,
      option,
      decodeurs,
      loadDecodeurs,
      loadFormules,
      formatDate,
      loadOptions,
      search,
      detailsFormatted,

      plurals,
      typeOperation,
      titleDetailsDialog,

      headers: [
        {
          text: 'Envoyé le', value: 'created_at', divider: true, align: 'center',
        },
        {
          text: 'Operation', value: 'request_type', divider: true, align: 'center',
        },
        {
          text: 'Montant', value: 'amount', divider: true, align: 'center',
        },
        {
          text: 'Net à payer', value: 'subsidised_amount', divider: true, align: 'center',
        },
        {
          text: 'Etat', value: 'state', sortable: false, divider: true, align: 'center',
        },
        {
          text: 'Action', value: 'actions', sortable: false, divider: true, align: 'center',
        },
      ],
      dataOptions: {
        sortBy: ['id'],
        sortDesc: [true],
      },
      headersDetails: [
        {
          text: 'Ticket', value: 'num_ticket', divider: true, align: 'center',
        },
        {
          text: 'Décodeur', value: 'decodeur', divider: true, align: 'center',
        },
        {
          text: 'Offre', value: 'offre', divider: true, align: 'center',
        },
        {
          text: 'Dates', value: 'date', divider: true, align: 'center',
        },
        {
          text: 'Montant', value: 'montant', divider: true, align: 'center',
        },
        {
          text: 'Net à payer', value: 'subsidised_amount', divider: true, align: 'center',
        },
        {
          text: 'Montant payé', value: 'subsidised_amount_paid', divider: true, align: 'center',
        },
        {
          text: 'Etat', value: 'state', divider: true, align: 'center',
        },
        {
          text: 'Commentaire', value: 'comment', divider: true, align: 'center',
        },
      ],
      detailsOptions: {
        sortBy: ['operation'],
        sortDesc: [false],
      },
      operationList,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      detailItem,
      dialog,
      dialogDelete: false,
      empty: false,
      contrat,
      inProccessing,
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
  },
  created() {
    moment.locale('fr')

    // this.$http.get('/data-table/data').then(res => {
    //   this.operationList = res.data
    // })
  },
  methods: {
    initialize() {
      this.userList = JSON.parse(JSON.stringify(data))
    },

    editItem(item) {
      console.log(item)
      this.editedIndex = this.operationList.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.operationList.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.operationList.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.operationList[this.editedIndex], this.editedItem)
      } else {
        this.userList.push(this.editedItem)
      }
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.custom-warning--text{
  color: darkorange;
  //background-color: #ffcfcf;
}

.bordure{
  border-left:1px solid #a8a5a5;
  border-bottom:1px solid #a8a5a5;
}

.custom-btn:hover{
  background-color: transparent !important;
}
</style>
